import { render, staticRenderFns } from "./ai_img.vue?vue&type=template&id=4ec2b5f5&scoped=true&"
import script from "./ai_img.vue?vue&type=script&lang=js&"
export * from "./ai_img.vue?vue&type=script&lang=js&"
import style0 from "./ai_img.vue?vue&type=style&index=0&id=4ec2b5f5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec2b5f5",
  null
  
)

export default component.exports
<template>
    <div>
        <!-- AI绘画- type = 1  其他需要上传图片的样式就是type = 2 -->
        <div v-if="type == 1">
            <div class="z-img" v-if="contentData == {}">
            </div>
            <img v-if="contentData != {}" class="z-img" :src="contentData.output_content" alt="">
        </div>
        <div class="create_img_box flex" v-if="type == 2">
            <div class="create_box">
                <div class="img_box">

                    <img v-if="contentData.origin_url == ''" src="@/assets/wordOp/none.png" alt="">
                    <img v-else :src="contentData.origin_url" alt="">
                </div>
                <p>原图</p>
            </div>
            <div class="create_box">
                <div class="img_box">
                    <img v-if="contentData.output_content == ''" src="@/assets/wordOp/none.png" alt="">
                    <img v-else :src="contentData.output_content" alt="">
                </div>
                <p>{{ contentName ? contentName : '上色后图片' }}</p>
            </div>
        </div>
        <button class="create_but" @click="downloadImage" :disabled="contentData.output_content == ''">下载图片</button>
    </div>
</template>

<script>
import { downloadByBlob } from '@/util/img_link'
export default {
    name: '',
    props: {
        type: Number,
        contentData: Object,
        contentName: {
            type: [String, null],
            default: ''
        }
    },
    created() { },
    mounted() {
    },
    data() {
        return {}
    },
    methods: {
        downloadImage() {
            let timing = parseInt(new Date().getTime() / 1000) + ''
            let imgName = `${this.$route.meta}${timing}`
            downloadByBlob(this.contentData.output_content, imgName)
        }

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>